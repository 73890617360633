.reports-link {
    color: white;
    font-size: 20px;
    text-decoration: none !important;
    margin-left: 20px;
}

.navbar-right {
    margin-right: 10px;
}

.footer {
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 50px;
    line-height: 60px;
    text-align: center;
    background-color: #f5f5f5;
    font-size: 12px;
}